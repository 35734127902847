.ui.secondary.pointing.menu .item {
    border-bottom-width: 4px;

}
/*.ui.secondary.inverted.pointing.menu .active.item {*/
    /*border-color: rgb(97, 218, 251);*/

/*}*/

/*.ui.secondary.inverted.menu .active.item {*/

    /*color: rgb(97, 218, 251) !important;*/
/*}*/

/*.ui.primary.button, .ui.primary.buttons .button {*/
    /*background-color: rgb(97, 218, 251) !important;*/
    /*color: black;*/

/*}*/


.box {
    display: flex ;
    flex-flow: column;

    overflow: auto;
}


.box .row.header {
    flex: 0 1 auto ;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}

.box .row.content {
    flex: 1 1 auto ;
    min-height: 650px ;
}

.box .row.footer {
    flex: 0 1 auto ;
}