body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: GoodTimes;
  src: url("/static/media/good times rg.39636dd7.ttf");
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ui.secondary.pointing.menu .item {
    border-bottom-width: 4px;

}
/*.ui.secondary.inverted.pointing.menu .active.item {*/
    /*border-color: rgb(97, 218, 251);*/

/*}*/

/*.ui.secondary.inverted.menu .active.item {*/

    /*color: rgb(97, 218, 251) !important;*/
/*}*/

/*.ui.primary.button, .ui.primary.buttons .button {*/
    /*background-color: rgb(97, 218, 251) !important;*/
    /*color: black;*/

/*}*/


.box {
    display: flex ;
    flex-flow: column;

    overflow: auto;
}


.box .row.header {
    flex: 0 1 auto ;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}

.box .row.content {
    flex: 1 1 auto ;
    min-height: 650px ;
}

.box .row.footer {
    flex: 0 1 auto ;
}
